import React, { useState, useRef, useEffect } from 'react';
import loginSound from '../sounds/teruhbdLP_login.mp3';
import fullSound from '../sounds/teruhbdLP_full.mp3';

const Bgm = (props) => {
  const [muted, setMute] = useState(true);
  const isMute = window.localStorage.getItem("isMute");
  // const btn_play = document.getElementById("btn_play");
  // const btn_pause = document.getElementById("btn_pause");
  //const btn_mute = document.getElementById("btn_mute");
  //const audioElement = document.querySelector("audio");
  const audioElement = useRef(null);
  const btnPlay = useRef(null);
  const btnMute = useRef(null);

  //console.log(props.sound);
  console.log("isMute: " + isMute );

  audioElement.loop = true;

  if(!isMute){
    audioElement.muted = false;
    //volumeOn();
  }
  
  function volumeOn(){
    audioElement.muted = false;
    audioElement.current.play();
    setMute(false);
    localStorage.setItem("isMute", JSON.stringify(false));
    console.log("isMute:" + window.localStorage.getItem("isMute"));
  }

  function volumeOff(){
    audioElement.muted = true;
    audioElement.current.pause();
    setMute(true);
    localStorage.setItem("isMute", JSON.stringify(true));
    console.log("isMute:" + window.localStorage.getItem("isMute"));
  }
  

  return (
    <div class="audio_wrap">
      <p>sound</p>
      {/* <input id="volumeControl" defaultValue="0.5" type="range" min="0" max="1" step="0.1" value="0.5"></input> */}
      {/* <button onClick={() => play()}>Play</button> */}
      <audio ref={audioElement} id="soundbgm" preload="auto" controls loop>
        {props.sound
            ? <source controls src={fullSound} type="audio/mp3"></source>
            : <source controls src={loginSound} type="audio/mp3"></source>
        }
        <p>※ご利用のブラウザでは再生できません</p>
      </audio>
      <div className='sound-controls'>
        <button id="btn_unmute" ref={btnPlay} onClick={() => volumeOn()} className={muted ? "" : "active"}>On</button>
        <button id="btn_mute" ref={btnMute} onClick={() => volumeOff()} className={muted ? "active" : ""}>Off</button>
        {/* <input ref={volumeControl} type="range" value="0.5" min="0.0" max="1.0" step="0.1" /> */}
      </div>
    </div>
  )
}

export default Bgm;