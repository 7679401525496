import React from 'react';
import { Link, useNavigate } from "react-router-dom";

const Footer = (isAuth) => {

    isAuth = JSON.parse(window.localStorage.getItem("isAuth"));

    const navigate = useNavigate();

    const logout = () => {
        localStorage.setItem("isAuth", false);
        localStorage.clear();
        isAuth = false;
        navigate("/");
    };

  return (
    <footer>
        
        {/* <p>Have a nice year... from Noriko &amp; Kuki</p> */}
        <ul>
            {/* <li><Link to="/" activeClassName="active">HOME</Link></li> */}
            <li>
                {isAuth ? (
                  <Link to="/map" >MAP</Link>
                ) : (
                  <Link to="/" activeClassName="active">TOPへ戻る</Link>
                    // <Link to="/logout" onClick={click}>Logout</Link>
                )}
            </li>
            <li>
                {isAuth ? (
                    <Link to="/logout" onClick={logout}>LOGOUT</Link>
                ) : (
                    <Link to="/">LOGIN</Link>
                    // <Link to="/logout" onClick={click}>Logout</Link>
                )}
            </li>
        </ul>
    </footer>
  )
}

export default Footer;