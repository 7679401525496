import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import GoogleMap from "./GoogleMap";
import Bgm from "./Bgm";

const Map = (isAuth) => {
    isAuth = JSON.parse(window.localStorage.getItem("isAuth"));
    //console.log(isAuth);
    if(isAuth){
      return (
        <motion.main
          className='container'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
          >  
            <div className='map'>
              <div className='introduction'>
                <h2>Happy Birthday 2024<br />
                on <br className='sp'/>March 29th</h2>
                <p>
                誕生日おめでとう!<br />
                本年も貴殿の生誕日という<br className='sp'/>善き地点に立ち会えること、<br className='sp'/>ざわ一同 誠に光栄です。<br />
                輝かしい一年の幕開けと<br className='sp'/>春の訪れを楽しむべく、<br />ささやかな祝宴を用意しましたので<br className='sp'/>主賓として御列席願います。<br />
                <br />
                日時: 3月31日(日)13時30分<br />
                場所: <a href="https://maps.app.goo.gl/4C45icB7e4E2Ci7L8" target='_blank' className='link'>Zawa and the Crimson Stone</a><br />
                服装: <br className='sp'/>春の妖精 <br className='sp'/>〜踵は低めで〜
                </p>
                
              </div>
              <div id="googlemap" className='googlemap'>
                <GoogleMap 
                  defaultPosition={{
                    lat: 35.6811162,
                    lng: 139.7782008
                  }}
                />
              </div>
              <div className='caption-list'>
                <p>Zawa and the Seven Portraits<br />
                Chapter 1. Zawa and the Crimson Stone<br />
                <br />
                ざわと七つの肖像<br />
                第一章:ざわと真紅の石</p>
                {/* <ol>
                  <li>Zawa and the Crimson Stone / zawaと真紅の石 @KABUTO ONE</li>
                  <li>Zawa and the Pledged Luncheon / zawaと約束の午餐 @Bistro yen </li>
                  <li>Zawa and the 始まりの Bank / zawaと... @銀行発祥の地</li>
                  <li>Zawa and the Three Statues / Zawaと三つの.. @3人の乙女</li>
                  <li>Zawa and the 〇〇 Shrine / zawaと... @日本橋日枝神社分社</li>
                  <li>Zawa and the 〇〇の井戸 / zawaと.... @名水白木の井戸</li>
                  <li>Zawa and the Seven-Fortunes’ Garden /  zawaと七福の庭園 @名水白木の井戸</li>
                </ol> */}
              </div>
            </div>
            <Bgm sound="full"/>
        </motion.main>
      )
    }else{
      return(
        <main className='container'>
          <p className="instruction">このページを見るには<br className='sp'/>ログインが必要です。<br />
          <Link to="/">ログイン画面へ</Link></p>
        </main>

      )
    }

}

export default Map;