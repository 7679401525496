import { useJsApiLoader } from "@react-google-maps/api";
import mapicon from '../images/zawa_mapicon.png';

export type Map = google.maps.Map;

type Props = {
  defaultPosition: { lat: number; lng: number }
};


var markerData = [ {
      name: 'Kabuto One',
      lat: 35.6990772, lng: 139.7857425,
      icon: 'images/mapicon.png'
  }, {
    name: '3人の乙女像',
    lat: 35.6817983, lng: 139.7771308
  }, {
    name: '日本橋日枝神社',
    lat: 35.6809423, lng: 139.7764717
  }, {
    name: '白木屋の井戸',
    lat: 35.6825987, lng: 139.7735373
  }, {
    name: '庭園',
    lat: 35.6815503, lng: 139.7743421
  }
];

export const useMap = ({ defaultPosition }: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: "AIzaSyBkePHhJhWwahTx7kzBnijpdJa0m3BZwY4",
  });


  const onLoad = (mymap: Map) => {
    const bounds = new window.google.maps.LatLngBounds(defaultPosition);
    mymap.fitBounds(bounds);

    var listener = google.maps.event.addListener(mymap, "idle", function() { 
      if (mymap.getZoom() > 15) mymap.setZoom(15); 
      google.maps.event.removeListener(listener); 
    });

    // mark at start position
    var mkimg = new google.maps.MarkerImage(mapicon, null, null, null, new google.maps.Size(50, 50) );
    var startPos = new google.maps.Marker({
      position: defaultPosition,
      map: mymap,
      title : "KABUTO ONE",
      icon: mkimg
    });

    var mapOptions = {
      styles: [
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#6195a0"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#e6f3d6"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#f4d2c5"
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text",
            "stylers": [
                {
                    "color": "#4e4e4e"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#f4f4f4"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#787878"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#eaf6f8"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#eaf6f8"
                }
            ]
        }
    ]
    }

    mymap.setOptions( mapOptions ) ;

    // マーカーの作成
    for (var i = 0; i < markerData.length; i++) {
        var markerLatLng = new google.maps.LatLng({lat: markerData[i]['lat'], lng: markerData[i]['lng']}); // 緯度経度のデータ作成
        // marker[i] = new google.maps.Marker({ // マーカーの追加
        //     position: markerLatLng, // マーカーを立てる位置を指定
        //     map: mymap
        // });

        //if(markerData[i]['icon']){
          // marker[i].setOptions({
          //   icon: {
          //     url: markerData[i]['icon'] // マーカーの画像を変更 
          //   }
          // });
        //}

      // infoWindow[i] = new google.maps.InfoWindow({ // 吹き出しの追加
      //   content: '<div class="sample">' + markerData[i]['name'] + '</div>' // 吹き出しに表示する内容
      // });

      // marker[i].addListener('click', function() { // マーカーをクリックしたとき
      //   infoWindow[i].open(mymap, marker[i]); // 吹き出しの表示
      // });

    };
    
  };

  return { isLoaded, onLoad };
};